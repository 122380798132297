import { Link } from "react-router-dom";
import React from "react";
import styled from "styled-components";
import { blog } from "../../urls";
import { ClockCircleOutlined, UserOutlined } from "@ant-design/icons";
import { colors } from "../../utils/theme";
import dayjs from "dayjs";
import { DATE_FORMAT_DD_MM_YYYY } from "../../constants/DateConstants";

const BlogPostItem = ({ post }) => (
  <div className={"no-select"}>
    <StyledLink to={blog + "/" + post.url}>
      <Post>
        <div className={"image"}>
          <img src={post.image} alt={post.image_alt} />
        </div>
        {!!post.category && <p className={"tag"}>{post.category}</p>}
        <h3>{post.title}</h3>
        <div className={"d-flex"}>
          <p style={{ marginRight: 12 }}>
            <ClockCircleOutlined
              style={{
                color: colors.primary,
                marginRight: 6,
              }}
            />
            {dayjs.unix(post.createdAt).format(DATE_FORMAT_DD_MM_YYYY)}
          </p>
          {!!post.createdBy && (
            <p>
              <UserOutlined
                style={{
                  color: colors.primary,
                  marginRight: 6,
                }}
              />
              {post.createdBy}
            </p>
          )}
        </div>
      </Post>
    </StyledLink>
  </div>
);

const Post = styled.div`
  position: relative;

  .image {
    overflow: hidden;
    position: relative;
    border-radius: 24px;
    box-shadow: 0 0 12px 4px rgba(0, 0, 0, 0.15);
    margin-bottom: 1rem;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  p.tag {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: ${colors.featuredTag};
    color: white;
    border-radius: 20px;
    padding: 4px 12px;
    font-weight: 500;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    text-transform: capitalize;
  }
`;

const StyledLink = styled(Link)`
  &:hover img {
    transform: scale(1.05);
  }
`;

export default BlogPostItem;
