import React, { Fragment, useEffect, useState } from "react";
import Layout from "../../../layouts/Layout";
import { useTranslation } from "react-i18next";
import { WEBSITE_NAME } from "../../../constants/CompanyConstants";
import { Helmet } from "react-helmet";
import { BreadcrumbStructuredData } from "../../../components/structured-data/BreadcrumbStructuredData";
import {
  BREADCRUMB_BLOG,
  BREADCRUMB_HOME,
} from "../../../constants/BreadcrumbConstants";
import styled from "styled-components";
import { colors } from "../../../utils/theme";
import { Breadcrumb, Divider } from "antd";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Loader } from "../../../components/loader/Loader";
import BreadcrumbContainer from "../../../wrappers/breadcrumb/BreadcrumbContainer";
import urls from "../../../urls";
import {
  ClockCircleOutlined,
  FormOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import { device } from "../../../utils/breakpoints";
import dayjs from "dayjs";
import { DATE_FORMAT_DD_MM_YYYY } from "../../../constants/DateConstants";
import BlogMarkdown from "../../../components/core/BlogMarkdown";

const BlogDetail = () => {
  const { t } = useTranslation();
  const { url } = useParams();
  const blogPosts = useSelector(
    (state) => state.firestoreReducer.ordered.blogPosts
  );
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!post && !!blogPosts) {
      setLoading(true);
      const post = blogPosts.find((post) => post.url === url);
      setPost(post);
      setLoading(false);
    }
  }, [blogPosts]);

  return (
    <Fragment>
      {!!post && (
        <Helmet>
          <title>
            {post.title} · {t("blog")} · {WEBSITE_NAME}
          </title>
          <meta name="description" content={post.metaDescription} />
          {BreadcrumbStructuredData([
            BREADCRUMB_HOME,
            BREADCRUMB_BLOG,
            {
              name: post.title,
              url:
                process.env.REACT_APP_WEBSITE_URL + urls.blog + "/" + post.url,
            },
          ])}
        </Helmet>
      )}
      <Layout headerTop="visible">
        {/* breadcrumb */}
        <BreadcrumbContainer>
          <Breadcrumb separator={">"}>
            <Breadcrumb.Item>
              <Link
                to={urls.blog}
                style={{ display: "flex", alignItems: "center" }}
              >
                <LeftOutlined style={{ marginRight: 6 }} />
                {t("all_blog_posts")}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </BreadcrumbContainer>
        <PageWrapper className={"container"}>
          {!loading && !!post ? (
            <>
              <Post>
                <img
                  src={post.image}
                  alt={!!post.imageAlt ? post.imageAlt : post.title}
                />
                <div className={"information"}>
                  <h1>{post.title}</h1>
                  <div className={"d-flex"}>
                    <p style={{ marginRight: 12 }}>
                      <ClockCircleOutlined
                        style={{
                          color: colors.primary,
                          marginRight: 6,
                        }}
                      />
                      {dayjs
                        .unix(post.createdAt)
                        .format(DATE_FORMAT_DD_MM_YYYY)}
                    </p>
                    {!!post.createdBy && (
                      <p>
                        <FormOutlined
                          style={{
                            color: colors.primary,
                            marginRight: 6,
                          }}
                        />
                        {post.createdBy}
                      </p>
                    )}
                  </div>
                  <p className={"tag"}>{post.category}</p>
                  <Divider />
                  <BlogMarkdown>{post.content}</BlogMarkdown>
                </div>
              </Post>
            </>
          ) : (
            <Loader />
          )}
        </PageWrapper>
      </Layout>
    </Fragment>
  );
};

const VideoWrapper = styled.div`
  margin-top: 2rem;

  iframe {
    box-shadow: 0 6px 20px rgba(84, 84, 84, 0.15);
    border-radius: 10px;
  }

  @media ${device.lg} {
    margin-top: 2rem;
  }
`;

const ProductsWrapper = styled.div``;

const Post = styled.div`
  position: relative;

  img {
    border-radius: 6px;
    margin-bottom: 3rem;
    box-shadow: 0 0 10px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: auto;
  }

  h3 {
    color: ${colors.primaryDark};
    font-weight: 600;
  }

  .information {
    display: flex;
    flex-direction: column;

    h1 {
      font-size: 48px;
      font-weight: 600;
      margin-bottom: 1rem;
      color: ${colors.primaryDark};
      font-family: "Krusyida Script";
    }

    p {
      white-space: pre-line;

      &.description {
        font-size: 18px;
        line-height: 1.75;
      }

      &.tag {
        background-color: ${colors.featuredTag};
        width: fit-content;
        color: white;
        border-radius: 20px;
        padding: 4px 12px;
        font-weight: 500;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
        text-transform: capitalize;
        margin: 0;
      }
    }
  }
`;

const PageWrapper = styled.div`
  padding-bottom: 4rem;
  padding-top: 1rem;
`;

export default BlogDetail;
