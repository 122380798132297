let paymentCheckout =
  process.env.REACT_APP_API_URL + "/api/payment/stripe/checkout";
let mailService = process.env.REACT_APP_API_URL + "/api/email";
let authService = process.env.REACT_APP_API_URL + "/api/auth";
let mailchimpService = process.env.REACT_APP_API_URL + "/api/mailchimp";
let payCheckout = process.env.REACT_APP_API_URL + "/api/payment/pay/checkout";
module.exports = {
  home: "/",
  about: "/over",
  contact: "/contact",
  my_account: "/mijn-account",
  my_orders: "/mijn-bestellingen",
  login_register: "/inloggen-registreren",
  login: "/inloggen",
  register: "/registreren",
  forgot_password: "/reset-wachtwoord",
  cart: "/winkelwagen",
  wishlist: "/favorieten",
  checkout: "/bestellen",
  document_id_param: ":documentId",
  order_processing: "/bestelling/verwerken/",
  order_confirmed: "/bestelling/bevestigd",
  order_failed: "/bestelling/mislukt",
  not_found: "/niet-gevonden",
  shopLandingPage: "/shop",
  shop: "/producten",
  productsByCategory: "/producten/:urlCategory",
  productsSlug: "/producten/",
  product: "/product/",
  productDetail: "/product/:url",
  categorySlug: "/categorie/",
  categoryLandingPage: "/categorie/:category",
  subcategoryLandingPage: "/categorie/:category/:subcategory",
  privacy: "/privacy",
  ideal_checkout: paymentCheckout + "/ideal",
  credit_card_checkout: paymentCheckout + "/creditcard",
  ban_contact_checkout: paymentCheckout + "/bancontact",
  order_confirmed_email: mailService + "/order/confirmed",
  order_processing_redirect_success: "?redirect_status=succeeded",
  order_processing_redirect_failed: "?redirect_status=failed",
  contact_email: mailService + "/contact",
  order_invoice: mailService + "/order/invoice",
  welcome_email: mailService + "/signup/welcome",
  verify_email: mailService + "/signup/verify",
  email_verified: "/email/verificatie/:encodedEmail",
  email_verified_link: "/email/verificatie/",
  verify_user_email: authService + "/verify/",
  delivery_returns: "/verzenden-retourneren",
  faq: "/veelgestelde-vragen",
  halal_slager: "/halal-slager",
  certificates: "/certificaten",
  winkel: "/winkel",
  packaging: "/verpakking",
  grasgevoerd: "/grasgevoerd-halal-vlees",
  unsubscribe_mails: "/uitschrijven/:url",
  recipes: "/recepten",
  recipesDetail: "/recepten/:url",
  blog: "/blog",
  blogDetail: "/blog/:url",
  mailchimp_subscribe: mailchimpService + "/subscribe",
  payCheckout,
};
