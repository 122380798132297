import React, { Fragment } from "react";
import Layout from "../../../layouts/Layout";
import { useTranslation } from "react-i18next";
import { WEBSITE_NAME } from "../../../constants/CompanyConstants";
import {
  META_BLOG_DESCRIPTION,
  META_BLOG_TITLE,
} from "../../../constants/MetaTagsConstants";
import { Helmet } from "react-helmet";
import { BreadcrumbStructuredData } from "../../../components/structured-data/BreadcrumbStructuredData";
import {
  BREADCRUMB_BLOG,
  BREADCRUMB_HOME,
} from "../../../constants/BreadcrumbConstants";
import styled from "styled-components";
import { colors } from "../../../utils/theme";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { BackgroundElement } from "../../../styles/SharedStyles";
import { device } from "../../../utils/breakpoints";
import { Container } from "react-bootstrap";
import BlogPostItem from "../../../components/elements/BlogPostItem";

const BlogOverview = () => {
  const { t } = useTranslation();
  const BLOGPOSTS = useSelector(
    (state) => state.firestoreReducer.ordered.blogPosts
  );

  return (
    <Fragment>
      <Helmet>
        <title>
          {META_BLOG_TITLE} · {WEBSITE_NAME}
        </title>
        <meta name="description" content={META_BLOG_DESCRIPTION} />
        {BreadcrumbStructuredData([BREADCRUMB_HOME, BREADCRUMB_BLOG])}
      </Helmet>
      <Layout headerTop="visible">
        <PageWrapper>
          <HerbsAndSpicesBgElement
            src={"/assets/img/styling/herbs-and-spices-1.webp"}
            alt={""}
          />
          <BrownSpiceBgElement
            src={"/assets/img/styling/spice-splash-brown.webp"}
            alt={""}
          />
          <RedSpiceBgElement
            src={"/assets/img/styling/spices-6.png"}
            alt={""}
          />
          <Container>
            <Hero>
              <h1>{t("blog_page_title")}</h1>
              <p>{t("blog_page_description")}</p>
            </Hero>
            <BlogOverviewContainer>
              <Row gutter={[32, 32]}>
                {!!BLOGPOSTS &&
                  BLOGPOSTS.map((post, index) => (
                    <Col lg={8} key={index}>
                      <BlogPostItem post={post} />
                    </Col>
                  ))}
              </Row>
            </BlogOverviewContainer>
          </Container>
        </PageWrapper>
      </Layout>
    </Fragment>
  );
};

const Recipe = styled.div`
  position: relative;

  .image {
    overflow: hidden;
    position: relative;
    border-radius: 24px;
    box-shadow: 0 0 12px 4px rgba(0, 0, 0, 0.15);
    margin-bottom: 1rem;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  p.tag {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: ${colors.featuredTag};
    color: white;
    border-radius: 20px;
    padding: 4px 12px;
    font-weight: 500;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    text-transform: capitalize;
  }
`;

const StyledLink = styled(Link)`
  &:hover img {
    transform: scale(1.05);
  }
`;

const BlogOverviewContainer = styled.div`
  margin: 5rem 0;
`;

const Hero = styled.div`
  text-align: center;

  h1 {
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 1rem;
    color: ${colors.primaryDark};
    font-family: "Krusyida Script";
  }

  p {
    white-space: pre-line;
    font-size: 18px;
    line-height: 1.75;
  }
`;

const PageWrapper = styled.section`
  padding-top: 4rem;
  padding-bottom: 4rem;
  overflow: hidden;
  position: relative;
`;

const HerbsAndSpicesBgElement = styled(BackgroundElement)`
  transform: rotate(-75deg);
  opacity: 0.7;
  width: 240px;
  top: -50px;
  left: -140px;

  @media ${device.sm} {
    opacity: 1;
    left: -150px;
  }

  @media ${device.md} {
    top: -50px;
    left: -120px;
  }

  @media ${device.lg} {
    width: 260px;
    top: -60px;
    left: -120px;
  }

  @media ${device.xl} {
    left: -150px;
  }

  @media ${device.xxl} {
    top: -32px;
    width: 320px;
  }
`;

const BrownSpiceBgElement = styled(BackgroundElement)`
  display: none;

  @media ${device.lg} {
    display: block;
    bottom: 0;
    opacity: 0.7;
    right: -580px;
  }
`;

const RedSpiceBgElement = styled(BackgroundElement)`
  width: 250px;
  display: none;

  @media ${device.lg} {
    display: block;
    transform: rotate(-90deg);
    bottom: 25%;
    right: -90px;
  }
`;

export default BlogOverview;
