import React, { lazy, Suspense, useEffect, useState } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./translations/i18n";
import urls from "./urls";
import ForgotPassword from "./pages/other/ForgotPassword";
import { useFirestoreConnect } from "react-redux-firebase";
import { Loader } from "./components/loader/Loader";
import { ORDER_STATUS_CREATED } from "./constants/OrderConstants";
import { updateCart } from "./redux/actions/cartActions";
import { notification } from "antd";

import CookieBanner from "./components/banner/CookieBanner";
import {
  FIRESTORE_BLOG_POSTS_TABLE,
  FIRESTORE_CATEGORIES_TABLE,
  FIRESTORE_FEATURED_PRODUCTS_TABLE,
  FIRESTORE_INSTAGRAM_POSTS_TABLE,
  FIRESTORE_LOYALTY_CLAIMS_TABLE,
  FIRESTORE_LOYALTY_REWARDS_TABLE,
  FIRESTORE_LOYALTY_USERS_TABLE,
  FIRESTORE_ORDER_LIMITS_TABLE,
  FIRESTORE_ORDERS_TABLE,
  FIRESTORE_PRODUCTS_TABLE,
  FIRESTORE_RECIPES_TABLE,
} from "./constants/FirebaseConstants";
import RecipesDetail from "./pages/hvo/Recipes/RecipesDetail";

import dayjs from "dayjs";
import "dayjs/locale/nl";
import "dayjs/locale/en";
import dayOfYear from "dayjs/plugin/dayOfYear";
import customParseFormat from "dayjs/plugin/customParseFormat";
import LoyaltyProgramModule from "./components/loyalty-program/LoyaltyProgramModule";
import { ErrorBoundary } from "react-error-boundary";
import { sendExceptionToSlack } from "./services/api/exception";
import ErrorBoundaryFallback from "./components/core/ErrorBoundaryFallback";
import BlogDetail from "./pages/hvo/Blog/BlogDetail";
import BlogOverview from "./pages/hvo/Blog/BlogOverview";

dayjs.locale("nl");

dayjs.extend(dayOfYear);
dayjs.extend(customParseFormat);

const Home = lazy(() => import("./pages/home/Home"));
// shop pages
const ShopListStandard = lazy(() => import("./pages/shop/ShopList"));
const CategoryLandingPage = lazy(() => import("./pages/shop/CategoryLanding"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const MyOrders = lazy(() => import("./pages/other/MyOrders"));
const Login = lazy(() => import("./pages/other/Login"));
const Register = lazy(() => import("./pages/other/Register"));
const VerifyEmail = lazy(() => import("./pages/other/VerifyEmail"));
const Packaging = lazy(() => import("./pages/hvo/Packaging"));
const Winkel = lazy(() => import("./pages/hvo/Winkel"));
const Grasgevoerd = lazy(() => import("./pages/hvo/Grasgevoerd"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const OrderConfirmed = lazy(() => import("./pages/other/OrderConfirmed"));
const OrderFailed = lazy(() => import("./pages/other/OrderFailed"));
const OrderProcessing = lazy(() => import("./pages/other/OrderProcessing"));
const PrivacyPolicy = lazy(() => import("./pages/other/PrivacyPolicy"));
const ShippingReturns = lazy(() => import("./pages/other/ShippingReturns"));
const FAQ = lazy(() => import("./pages/other/FAQ"));
const UnsubscribeMails = lazy(() => import("./pages/other/UnsubscribeMails"));

const NotFound = lazy(() => import("./pages/other/NotFound"));

const HalalSlager = lazy(() => import("./pages/hvo/HalalSlager"));
const HalalCertificaten = lazy(() => import("./pages/hvo/HalalCertificates"));
const ReceptenOverview = lazy(() =>
  import("./pages/hvo/Recipes/RecipesOverview")
);

const App = () => {
  const auth = useSelector((state) => state.firebaseReducer.auth);
  const email = auth?.email;
  const uid = auth?.uid;

  let queries = [
    { collection: FIRESTORE_PRODUCTS_TABLE },
    { collection: FIRESTORE_FEATURED_PRODUCTS_TABLE },
    { collection: FIRESTORE_RECIPES_TABLE, orderBy: ["createdAt", "desc"] },
    {
      collection: FIRESTORE_ORDER_LIMITS_TABLE,
    },
    {
      collection: FIRESTORE_CATEGORIES_TABLE,
      orderBy: "name",
    },
    { collection: FIRESTORE_LOYALTY_REWARDS_TABLE, orderBy: "points" },
    {
      collection: FIRESTORE_INSTAGRAM_POSTS_TABLE,
      orderBy: ["timestamp", "desc"],
    },
    { collection: FIRESTORE_BLOG_POSTS_TABLE, orderBy: ["createdAt", "desc"] },
  ];
  // If user is logged in, get his/her orders
  if (!!email)
    queries.push(
      {
        collection: FIRESTORE_ORDERS_TABLE,
        where: [
          ["customer.email", "==", email],
          ["orderStatus", "!=", ORDER_STATUS_CREATED],
        ],
      },
      {
        collection: FIRESTORE_LOYALTY_CLAIMS_TABLE,
        where: [
          ["userId", "==", uid],
          ["cashedIn", "==", false],
        ],
        orderBy: [["rewardType", "desc"], "points"],
      },
      {
        collection: FIRESTORE_LOYALTY_USERS_TABLE,
        doc: uid,
        storeAs: "loyaltyUser",
      }
    );
  useFirestoreConnect(queries);

  const dispatch = useDispatch();
  const products = useSelector(
    (state) => state.firestoreReducer.ordered.products
  );
  const orderLimits = useSelector(
    (state) => state.firestoreReducer.ordered.orderLimits
  );
  const [notificationsShown, setNotificationsShown] = useState(false);
  const [lastErrorLog, setLastErrorLog] = useState(null);

  useEffect(() => {
    dispatch(updateCart(products));
  }, [products]);

  // useEffect(() => {
  //   // if (dayjs().week() === 51 && dayjs().day() < 6) showWeekNotification();
  // }, []);

  useEffect(() => {
    if (!notificationsShown && !!orderLimits && orderLimits.length > 0) {
      showNotification();
      setNotificationsShown(true);
    }
  }, [orderLimits]);

  const showNotification = () => {
    if (!!orderLimits && orderLimits.length > 0) {
      orderLimits.forEach((item) => {
        if (item.currentAmount >= item.limit && !!item.message) {
          const today = dayjs();
          const dayjsDate = dayjs(item.date, "DD-MM-YYYY");
          const isFutureDate =
            (dayjsDate.dayOfYear() > today.dayOfYear() &&
              dayjsDate.year() === today.year()) ||
            dayjsDate.year() > today.year();

          if (isFutureDate)
            notification.info({
              message: item.title,
              description: item.message,
              duration: 14,
              placement: "bottom",
            });
        }
      });
    }
  };

  const showChristmasNotification = () => {
    notification.info({
      message: "Kerst bestelling: laatste kans 20 december",
      description:
        "De week van 17 t/m 20 december is de laatste kans om een kerst bestelling te laten bezorgen. Afhalen kan wel, op 23 en 24 december.",
      duration: 14,
      placement: "bottom",
    });
  };

  const logError = (error) => {
    const now = Date.now();
    if (
      (process.env.REACT_APP_ENVIRONMENT !== "local" && !lastErrorLog) ||
      now - lastErrorLog > 5000
    ) {
      // 5 seconds cooldown
      const currentPage = window.location.pathname;
      sendExceptionToSlack(
        "Client crash op pagina " + currentPage + ": " + error.message
      );
      setLastErrorLog(now);
    }
  };

  if (!products) return <Loader />;
  return (
    <>
      <ErrorBoundary fallback={<ErrorBoundaryFallback />} onError={logError}>
        <Router>
          <ScrollToTop>
            <Suspense fallback={<Loader />}>
              <Switch>
                {/* Homepage */}
                <Route exact path={urls.home} component={Home} />

                {/* Shop pages */}
                <Route exact path={urls.shop} component={ShopListStandard} />
                <Route
                  exact
                  path={urls.shopLandingPage}
                  component={ShopListStandard}
                />
                <Route
                  exact
                  path={urls.productsByCategory}
                  component={ShopListStandard}
                />

                {/* Shop product pages */}
                <Route
                  path={urls.productDetail}
                  render={(routeProps) => (
                    <Product
                      {...routeProps}
                      key={routeProps.match.params.id}
                      clickedProduct={routeProps.location.product}
                    />
                  )}
                />

                {/* Category landing page */}
                <Route
                  exact
                  path={urls.categoryLandingPage}
                  component={CategoryLandingPage}
                />
                <Route
                  exact
                  path={urls.subcategoryLandingPage}
                  component={CategoryLandingPage}
                />
                {/* Other pages */}
                <Route path={urls.about} component={About} />
                <Route path={urls.contact} component={Contact} />
                <Route path={urls.my_account} component={MyAccount} />
                <Route path={urls.my_orders} component={MyOrders} />
                <Route path={urls.login} component={Login} />
                <Route path={urls.register} component={Register} />
                <Route path={urls.forgot_password} component={ForgotPassword} />
                <Route path={urls.email_verified} component={VerifyEmail} />
                <Route path={urls.privacy} component={PrivacyPolicy} />
                <Route path={urls.cart} component={Cart} />
                <Route path={urls.wishlist} component={Wishlist} />
                <Route path={urls.checkout} component={Checkout} />
                <Route path={urls.order_confirmed} component={OrderConfirmed} />
                <Route path={urls.order_failed} component={OrderFailed} />
                <Route
                  path={urls.order_processing + urls.document_id_param}
                  component={OrderProcessing}
                />
                <Route path={urls.not_found} component={NotFound} />
                <Route
                  path={urls.delivery_returns}
                  component={ShippingReturns}
                />
                <Route path={urls.faq} component={FAQ} />
                <Route
                  path={urls.unsubscribe_mails}
                  render={(routeProps) => (
                    <UnsubscribeMails
                      {...routeProps}
                      key={routeProps.match.params.id}
                    />
                  )}
                />
                {/* Landing pages */}
                <Route path={urls.grasgevoerd} component={Grasgevoerd} />
                <Route path={urls.packaging} component={Packaging} />
                <Route path={urls.halal_slager} component={HalalSlager} />
                <Route path={urls.certificates} component={HalalCertificaten} />
                <Route exact path={urls.winkel} component={Winkel} />
                <Route exact path={urls.recipes} component={ReceptenOverview} />
                <Route exact path={urls.blog} component={BlogOverview} />
                <Route
                  path={urls.recipesDetail}
                  render={(routeProps) => (
                    <RecipesDetail
                      {...routeProps}
                      key={routeProps.match.params.id}
                    />
                  )}
                />
                <Route
                  path={urls.blogDetail}
                  render={(routeProps) => (
                    <BlogDetail
                      {...routeProps}
                      key={routeProps.match.params.id}
                    />
                  )}
                />
                <Route exact component={NotFound} />
              </Switch>
            </Suspense>
          </ScrollToTop>
          <CookieBanner />
          <LoyaltyProgramModule />
        </Router>
      </ErrorBoundary>
    </>
  );
};

export default App;
